import { faBuilding, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Radio, Stack, Typography } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { currentDuerpAtom, userShortAtom } from "../../../../../atoms/Atoms";
import { ICompany, ICompanyMembershipOrganism } from "../../../../../interfaces/Company";
import { IDuerpLightQuestion, IDuerpSection } from "../../../../../interfaces/DuerpForm";
import { AccompanyingStatus, ProgressStatus } from "../../../../../interfaces/Form";
import { Role } from "../../../../../interfaces/User";
import { emptySection } from "../../../../../resources/AppConstants";
import colors from "../../../../../resources/cssConstant";
import { StatusCode } from "../../../../../resources/StatusCode";
import DuerpService from "../../../../../services/DuerpService";
import { stringifyErrorMessage } from "../../../../../utils/ConversionMethods";
import { downloadOiraForm } from "../../../../../utils/DownloadDocuments";
import { endSection, implicationSection, recapSection, validationSection } from "../../../../../utils/DuerpTransformation";
import { LinkifyText } from "../../../../../utils/LinkifyText";
import GenericButton from "../../../../Generics/buttons/GenericButton";
import GenericIconButton from "../../../../Generics/buttons/GenericIconButton";
import GenericDialog from "../../../../Generics/GenericDialog/GenericDialog";
import GenericTextField from "../../../../Generics/GenericTextField/GenericTextField";
import AccompanimentChip from "../../../AccompanimentChip/AccompanimentChip";
import DuerpEndPage from "../DuerpEndPage/DuerpEndPage";
import DuerpValidationPage from "../DuerpValidationPage/DuerpValidationPage";

interface SectionDescriptionProps {
  section: IDuerpSection;
  sectionIndex: number;
  company: ICompany;
  updateTree: () => void;
  goToPreviousPage: () => void;
  goToNextPage: () => void;
  updateSectionConcernedState: (isCompanyConcerned: boolean) => void;
}

const questionSchema = yup.object().shape({
  question: yup
    .string()
    .trim()
    .max(2000, "Le titre du risque ne doit pas excéder 2000 caractères.")
    .min(1, "Vous ne pouvez pas laisser le titre du risque vide."),
});

export default function SectionDescription({
  section,
  sectionIndex,
  updateSectionConcernedState,
  company,
  updateTree,
  goToPreviousPage,
  goToNextPage,
}: SectionDescriptionProps) {
  const user = useAtomValue(userShortAtom);
  const [duerp, setDuerp] = useAtom(currentDuerpAtom);
  const [sectionData, setSectionData] = useState<IDuerpSection>(emptySection);
  const [openFieldConfirmModal, setOpenFieldConfirmModal] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [customQuestions, setCustomQuestions] = useState<IDuerpLightQuestion[]>(
    duerp.sections[sectionIndex]?.questions ?? [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const isImplSalPage = sectionIndex === -1;
  const isFirstPage = sectionIndex === -2;
  const isValidationPage = sectionIndex === -3;
  const isEndPage = sectionIndex === -4;

  const {
    register: registerQuestion,
    handleSubmit: handleSubmitQuestion,
    reset: resetQuestion,
    formState: { errors: errorsQuestion, isValid: isValidQuestion },
  } = useForm({
    resolver: yupResolver(questionSchema),
    mode: "onTouched",
  });

  const fetchSectionData = async () => {
    switch (sectionIndex) {
      case -1: {
        setSectionData(implicationSection);
        break;
      }
      case -2: {
        setSectionData(recapSection);
        break;
      }
      case -3: {
        setSectionData(validationSection);
        break;
      }
      case -4: {
        setSectionData(endSection);
        break;
      }
      default: {
        const res = await DuerpService().getDuerpSection(duerp.uuid, section.uuid);
        if (res.data) {
          setSectionData(res.data);
        }
      }
    }
  };

  useEffect(() => {
    setIsDirty(false);
    setLoading(true);
    fetchSectionData().then(
      () => {
        setCustomQuestions(duerp.sections[sectionIndex]?.questions ?? []);
        setLoading(false);
      },
      () => {
        setLoading(false);
      },
    );
  }, [section.uuid]);

  const handleRadioChange = (event) => {
    const newSectionData = { ...sectionData };
    const isConcerned = event.target.value === "true";
    newSectionData.companyConcerned = isConcerned;
    updateSectionConcernedState(isConcerned);
    setSectionData(newSectionData);
    setIsDirty(true);
  };

  const saveSection = async () => {
    if (sectionData.optional && isDirty && duerp.progressStatus === ProgressStatus.NOT_FINALIZED) {
      const res = await DuerpService().updateDuerpSection(
        {
          companyConcerned: sectionData.companyConcerned,
        },
        duerp.uuid,
        section.uuid,
      );
      if (res.status === StatusCode.OK) {
        updateTree();
        goToNextPage();
      } else {
        toast.error(
          "Impossible de sauvegarder vos réponses pour le moment. Veuillez rafraîchir la page et ne pas continuer l’édition des autres questions.",
        );
      }
    } else {
      goToNextPage();
    }
  };

  const addQuestion = async (data: FieldValues) => {
    const newQuestions = [...customQuestions];
    const newQuestionAdd = { title: data.question };
    const res = await DuerpService().postDuerpQuestion(newQuestionAdd, duerp.uuid, section.uuid);
    if (res.status === StatusCode.OK) {
      const newQuest = res.data as IDuerpLightQuestion;
      newQuestions.push(newQuest);
      setCustomQuestions(newQuestions);
      updateTree();
      resetQuestion();
    }
  };

  const deleteQuestion = async (questionIndex: number) => {
    const updatedQuestionList = [...customQuestions];
    updatedQuestionList.splice(questionIndex, 1);

    const res = await DuerpService().deleteDuerpQuestion(duerp.uuid, section.uuid, customQuestions[questionIndex].uuid);
    if (res.status === StatusCode.NO_CONTENT) {
      updateTree();
      resetQuestion();
      setCustomQuestions(updatedQuestionList);
    }
  };

  const renderCustomSection = () => (
    <Stack>
      <Stack component="form" direction="row" spacing={2} sx={{ mr: 5, mb: 5 }}>
        <GenericTextField
          label="Titre du risque personnalisé"
          error={!!errorsQuestion.question}
          helperText={stringifyErrorMessage(errorsQuestion.question)}
          id="question"
          placeholder="Saisir un titre de risque personnalisé"
          register={registerQuestion("question")}
        />
        <GenericButton
          text="Ajouter ce risque"
          onClick={handleSubmitQuestion(addQuestion)}
          disabled={!isValidQuestion || duerp.progressStatus !== ProgressStatus.NOT_FINALIZED}
        />
      </Stack>
      <Typography variant="body2" sx={{ mb: 1 }}>
        Risques personnalisés ajoutés
      </Typography>
      {customQuestions.length === 0 ? (
        <Typography variant="body1">Aucun risque ajouté</Typography>
      ) : (
        customQuestions.map((question, index) => (
          <Stack direction="row" key={question.uuid} alignItems="center">
            <Typography variant="body1">{question.title}</Typography>
            <GenericIconButton
              size="sm"
              onClick={() => deleteQuestion(index)}
              icon={faTrash}
              tooltip={
                user.role === Role.COMPANY_USER && question.addByExpert
                  ? "Vous ne pouvez pas supprimer un risque ajouté par un préventeur"
                  : "Supprimer ce risque"
              }
              disabled={
                (user.role === Role.COMPANY_USER && question.addByExpert) ||
                duerp.progressStatus !== ProgressStatus.NOT_FINALIZED
              }
              color={colors.deleteIcon}
            />
          </Stack>
        ))
      )}
    </Stack>
  );

  const changeToFieldAccompaniement = async () => {
    const res = await DuerpService().patchFieldAccompaniment(duerp.uuid);
    if (res.status === StatusCode.OK) {
      toast.success("Votre DUERP a bien changé de statut.");

      setDuerp(res.data);
    } else {
      toast.error(
        duerp.progressStatus !== ProgressStatus.NOT_FINALIZED
          ? "Vous ne pouvez pas modifier un DUERP archivé ou finalisé."
          : "Impossible de valider ce DUERP pour le moment.",
      );
    }
    setOpenFieldConfirmModal(false);
  };

  const renderConfirmFieldAccompaniementModal = () => (
    <GenericDialog
      openDialog={openFieldConfirmModal}
      handleClose={() => setOpenFieldConfirmModal(false)}
      title="Confirmation de l'Accompagnement Terrain"
      onValid={changeToFieldAccompaniement}
      confirmLabel="Confirmer"
    >
      <Typography variant="body1">
        Souhaitez-vous réellement changer le statut d'accompagnement du DUERP en "Accompagnement Terrain" ?
      </Typography>
    </GenericDialog>
  );

  const renderFirstPage = () => {
    const companyInfo = "Informations de l'entreprise";
    const companyName = "Raison sociale ou dénomination : ";
    const companyOrganism = "Votre service de prévention et de santé au travail : ";
    const membershipNumber = "Numéro d'adhérent SPSTI : ";
    const collaboratorsTitle = "Collaborateurs de l'entreprise : ";
    const membershipOrganism =
      company?.membershipOrganization === ICompanyMembershipOrganism.OTHER
        ? "Autre organisme"
        : company?.membershipOrganization.toString().replace("_", " ");
    const collaboratorsList = company.collaborators.concat(company.externalCollaborators);
    return (
      <Stack spacing={2} width="50%">
        {renderConfirmFieldAccompaniementModal()}
        <Typography variant="question" sx={{ mb: 3 }}>
          {companyInfo}
        </Typography>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Typography variant="subQuestion">{companyName}</Typography>
          <Typography variant="body1">{company?.companyName}</Typography>
        </Stack>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Typography variant="subQuestion">{companyOrganism}</Typography>
          <Typography variant="body1">{membershipOrganism}</Typography>
        </Stack>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Typography variant="subQuestion">{membershipNumber}</Typography>
          <Typography variant="body1">{company?.membershipNumber}</Typography>
        </Stack>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Typography variant="subQuestion">{collaboratorsTitle}</Typography>
          <Stack alignItems="flex-end" justifyContent="flex-end">
            {collaboratorsList.map((collaborator) => (
              <Typography key={collaborator} variant="body1">
                {collaborator}
              </Typography>
            ))}
          </Stack>
        </Stack>
        {user.role !== Role.COMPANY_USER && (
          <Stack direction="row" alignItems="baseline" justifyContent="space-between">
            <Typography variant="subQuestion">Statut d'accompagnement :</Typography>
            <Box>
              <AccompanimentChip accompanimentType={duerp.accompanyingStatus} />
              {duerp.accompanyingStatus !== AccompanyingStatus.FIELD_ACCOMPANIMENT && (
                <GenericIconButton
                  onClick={() => setOpenFieldConfirmModal(true)}
                  icon={faBuilding}
                  color={colors.primary}
                  disabled={
                    duerp.accompanyingStatus !== AccompanyingStatus.ACCOMPANIED &&
                    duerp.progressStatus !== ProgressStatus.NOT_FINALIZED
                  }
                  tooltip={
                    duerp.accompanyingStatus === AccompanyingStatus.ACCOMPANIED
                      ? "Passer en accompagnement terrain"
                      : ""
                  }
                />
              )}
            </Box>
          </Stack>
        )}
      </Stack>
    );
  };

  const renderOptionalQuestion = () => (
    <Stack spacing={3}>
      <Typography variant="question">Votre entreprise est-elle concernée par cette étape ?</Typography>

      <Stack spacing={1} alignItems="flex-start">
        <Stack direction="row" spacing={1} alignItems="center">
          <Radio
            disabled={duerp.progressStatus !== ProgressStatus.NOT_FINALIZED}
            value="true"
            checked={sectionData.companyConcerned}
            onChange={handleRadioChange}
          />
          <Typography variant="body1">Oui</Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Radio
            disabled={duerp.progressStatus !== ProgressStatus.NOT_FINALIZED}
            value="false"
            checked={!sectionData.companyConcerned}
            onChange={handleRadioChange}
          />
          <Typography variant="body1">Non</Typography>
        </Stack>
      </Stack>
    </Stack>
  );

  const downloadForm = async () => {
    setLoading(true);
    await downloadOiraForm(duerp.oiraFormUuid);
    setLoading(false);
  };

  const getNextBtnText = () => {
    if (duerp.progressStatus === ProgressStatus.FINALIZED) return "Suivant";
    return sectionData.optional && isDirty ? "Enregistrer et continuer" : "Continuer";
  };

  const renderInternalSection = () =>
    isValidationPage ? (
      <DuerpValidationPage
        companyUuid={company.uuid}
        updateTree={updateTree}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
      />
    ) : (
      <Box>
        <Typography variant="h3" sx={{ mb: 5 }}>
          {sectionData.title}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line", marginY: 10, width: "70%" }}>
          {LinkifyText(sectionData.description)}
        </Typography>
        {sectionData.customRisksSection ? renderCustomSection() : renderSection()}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 5 }}>
          <GenericButton onClick={() => goToPreviousPage()} text="Précédent" />
          <GenericButton onClick={() => saveSection()} text={getNextBtnText()} />
        </Stack>
      </Box>
    );

  const renderSection = () => (
    <>
      {sectionData.optional && renderOptionalQuestion()}
      {isFirstPage && renderFirstPage()}
      {sectionData.title === "Implication des salariés" && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <GenericButton
            text="Télécharger l'évaluation"
            icon={faDownload}
            onClick={() => downloadForm()}
            color={colors.primary}
            disabled={loading}
          />
        </Box>
      )}
    </>
  );

  return isEndPage ? (
    <DuerpEndPage companyUuid={company.uuid} goToPreviousPage={goToPreviousPage} />
  ) : (
    renderInternalSection()
  );
}
